import React, { useState } from "react";
import Zoom from "@mui/material/Zoom";
import { useTheme, styled } from "@mui/material/styles";

import { Button } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

const Blurb = ({ setShow }) => {
  const theme = useTheme();

  const StyledInfo = styled("div")(({ theme }) => ({
    ...theme.typography.h6,
    width: "fit-content",
    // margin: "auto",
    // marginTop: 20,
    padding: 10,
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "rgba(0, 0, 0, 0.15) 3.95px 3.95px 2.6px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      padding: 3,
      // margin: 4,
    },
    [theme.breakpoints.down("md")]: {
      // padding: 3,
      // margin: 2,
      // fontSize: "1em",
    },
  }));

  return (
    <Zoom in={true}>
      <StyledInfo
        style={{
          position: "relative",
          left: 0,
          top: 0,
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <div>
          {setShow && (
            <Button
              style={{
                ...theme.typography.button2,
                color: theme.palette.primary.contrastText,
                backgroundColor: theme.palette.primary.main,
                position: "absolute",
                top: -30,
                left: 0,
              }}
              onClick={() => setShow(false)}
            >
              <CloseIcon />
            </Button>
          )}
        </div>
        <div style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: 800 }}>bands </span>
          <a
            style={{ ...theme.typography.infoButton, whiteSpace: "nowrap" }}
            href="https://turnipthebeetmusic.com/videos/"
            target="_new"
          >
            Turnip the Beet
          </a>
          <sup>
            <i>&amp;</i>
          </sup>
          <a
            style={{ ...theme.typography.infoButton }}
            href="https://www.kingfisherband.com/"
            target="_new"
          >
            Kingfisher
          </a>
        </div>
        <div>
          <span style={{ fontWeight: 800 }}>callers </span>{" "}
          <a
            style={{ ...theme.typography.infoButton }}
            href="https://www.youtube.com/watch?v=9Fd3R4oVbnc"
            target="_new"
          >
            Tim Klein
          </a>
          <sup>
            <i>&amp;</i>
          </sup>
          <a
            style={{ ...theme.typography.infoButton }}
            href="https://www.youtube.com/playlist?list=PL67jrP2KH1rV3rRUY_M3ws75ar09zwNbj"
            target="_new"
          >
            Janet Shepherd
          </a>
        </div>

        <div>
          <span style={{ fontWeight: 800 }}>location </span>The Historic
          <br />
          1601 Rossville Ave
          <br />
          Chattanooga TN 37408
        </div>
      </StyledInfo>
    </Zoom>
  );
};

export default Blurb;
