import React from "react";
import PlaylistForm from "../components/Playlist/PlaylistForm.js";
import Header from "../components/Header";

const Playlists = () => {
  return (
    <Header title="cms/playlists">
      <h1>Playlists</h1>
      <PlaylistForm />
    </Header>
  );
};

export default Playlists;
