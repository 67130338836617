import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
const IconConfirmationButton = ({ id, Icon, Action, Args, Text }) => {
  const theme = useTheme();
  const [showConf, setShowConf] = useState(false);

  const handleClick = () => {
    setShowConf(false);
    Action(Args);
    console.log(Args);
  };

  if (!showConf) {
    return (
      <div
        onClick={() => setShowConf(true)}
        style={{
          cursor: "pointer",
          backgroundColor: theme.palette.grey[200],
          margin: 10,
          borderRadius: 5,
          boxShadow: theme.shadows[5],
        }}
      >
        <IconButton
          aria-label="delete"
          name={id}
          title={Text}
          // onClick={(event) => deleteMenuItem(event)}
        >
          <Icon />
        </IconButton>
        <div
          style={{
            ...theme.palette.action,
            cursor: "pointer",
          }}
        >
          {Text}
        </div>
      </div>
    );
  }

  return (
    <div
      onMouseLeave={() => setShowConf(false)}
      style={{
        position: "",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        zIndex: theme.zIndex.tooltip,
        borderRadius: 5,
        boxShadow: theme.shadows[20],
        padding: 5,
        margin: 5,
      }}
    >
      <div
        style={{
          ...theme.typography.body,
          display: "none",
          whiteSpace: "nowrap",
        }}
      >
        Confirm
      </div>
      <div
        onClick={() => handleClick()}
        style={{
          ...theme.typography.caption,
          whiteSpace: "nowrap",
          cursor: "pointer",
        }}
      >
        {Text}
      </div>
      <IconButton
        aria-label="delete"
        name={id}
        // onClick={(event) => deleteMenuItem(event)}
        onClick={() => handleClick()}
      >
        <Icon
          style={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.contrastText,
          }}
        />
      </IconButton>

      <div>
        <IconButton
          aria-label="delete"
          name={id}
          onClick={() => setShowConf(false)}
        >
          <BackspaceIcon />
        </IconButton>
        <font
          onClick={() => setShowConf(false)}
          style={{
            ...theme.typography.caption,
            whiteSpace: "nowrap",
            cursor: "pointer",
          }}
        >
          cancel
        </font>
      </div>
    </div>
  );
};

export default IconConfirmationButton;
