const webSafeFonts = [
  // Sans-Serif Fonts
  '"Roboto", static',
  '"PT Sans Caption", static',
  '"Arial", "Helvetica", sans-serif',
  '"Verdana", "Geneva", sans-serif',
  '"Tahoma", "Geneva", sans-serif',
  '"Trebuchet MS", sans-serif',
  '"Gill Sans", sans-serif',

  // Serif Fonts
  '"Times New Roman", "Times", serif',
  '"Georgia", serif',
  '"Palatino", "Palatino Linotype", "Book Antiqua", serif',

  // Monospace Fonts
  '"Courier New", "Courier", monospace',
  '"Lucida Console", monospace',
  '"Monaco", monospace',

  // Cursive Fonts
  '"Comic Sans MS", cursive',
  '"Brush Script MT", cursive',

  // Fantasy Fonts
  '"Impact", fantasy',
  '"Papyrus", fantasy',
];

export default webSafeFonts;
