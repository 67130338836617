import React, { useState } from "react";

import { useTheme, styled } from "@mui/material/styles";
import { Button } from "@mui/material";

import Blurb from "./Blurb";

import { prices, appConfig } from "../../config.js";

import Playlist from "../../components/Playlist";

export default function EventInfo() {
  const theme = useTheme();

  const earlyBirdDate = new Date(appConfig.EARLYBIRDDATE);
  const isEarlyBird = earlyBirdDate > new Date();

  const [price, setPrice] = useState(
    isEarlyBird
      ? Object.entries(prices)[0][1][0]
      : Object.entries(prices)[0][1][2]
  );
  const [studentPrice, setStudentPrice] = useState(
    isEarlyBird
      ? Object.entries(prices)[0][1][1]
      : Object.entries(prices)[0][1][3]
  );

  const lateBird = [
    Object.entries(prices)[0][1][2],
    Object.entries(prices)[0][1][3],
  ];

  const [showHeader, setShowHeader] = useState(true);

  const StyledHeader = styled("div")(({ theme }) => ({
    ...theme.typography.h4,
    width: "fit-content",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    borderRadius: 20,
    padding: 4,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {},
    [theme.breakpoints.down("md")]: {},
    [theme.breakpoints.down("sm")]: {
      padding: 2,
    },
  }));

  const StyledInfo = styled("div")(({ theme }) => ({
    ...theme.typography.h6,
    width: "fit-content",
    // margin: "auto",
    // marginTop: 20,
    padding: 10,
    borderRadius: 10,
    border: `2px solid ${theme.palette.primary.dark}`,
    boxShadow: "rgba(0, 0, 0, 0.15) 3.95px 3.95px 2.6px",
    [theme.breakpoints.down("xl")]: {},
    [theme.breakpoints.down("lg")]: {
      padding: 3,
      // margin: 4,
    },
    [theme.breakpoints.down("md")]: {
      // padding: 3,
      // margin: 2,
      // fontSize: "1em",
    },
  }));

  const Details = () => {
    const [show, setShow] = useState(true);
    if (!show) {
      return (
        <Button
          style={{
            ...theme.typography.button,
            ...theme.typography.h5,
            width: "fit-content",
            margin: 10,
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.primary.main,
          }}
          onClick={() => setShow(true)}
        >
          details...
        </Button>
      );
    }
    return <Blurb />;
  };
  // console.log(prices);
  // console.log(Object.entries(prices)[0][1][0]);

  //   [
  //     130,
  //     90,
  //     145,
  //     100
  // ]

  return (
    <div
      style={{
        border: "1px none black",
        display: showHeader ? "flex" : "none",

        flexDirection: "column",
        gap: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: 10,
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <StyledHeader
          style={{
            backgroundColor: theme.palette.secondary.main,
            width: 380,
            // maxWidth: 600,
            // minWidth: 280,
          }}
        >
          <i>Chattaboogie</i> 2025, January 10-12<sup>th</sup>
          <Details />
        </StyledHeader>

        <StyledInfo style={{ width: 380 }}>
          <div>
            {isEarlyBird
              ? "Early bird pricing through " +
                new Date(earlyBirdDate - 1).toDateString("en-US", {
                  timeZone: "UTC",
                })
              : ""}
          </div>
          <div>
            ${price}.00, student: ${studentPrice}{" "}
          </div>
          <div>
            {isEarlyBird
              ? `After  ${new Date(earlyBirdDate - 1).toDateString("en-US", {
                  timeZone: "UTC",
                })} the prices are $${lateBird[0]} / $${lateBird[1]}`
              : ""}
          </div>
        </StyledInfo>
      </div>
    </div>
  );
}
