import { Node } from "@tiptap/core";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client"; // Import the new API
import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useTheme, Box, Button, List, ListItem } from "@mui/material";

import { ThemeProvider } from "@mui/material/styles";
import Theme from "../../Theme.js"; // Import the exported theme

const PlaylistBlock = Node.create({
  name: "playlistBlock",
  group: "block",
  content: "block*",

  addAttributes() {
    return {
      playlist: {
        default: [],
        parseHTML: (element) =>
          JSON.parse(element.getAttribute("data-playlist")) || [],
        renderHTML: (attributes) => {
          return { "data-playlist": JSON.stringify(attributes.playlist) };
        },
      },
    };
  },

  parseHTML() {
    return [{ tag: "div[data-playlist-block]" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { "data-playlist-block": true, ...HTMLAttributes }, 0];
  },

  addNodeView() {
    return ({ node }) => {
      if (node.attrs.playlist.toString() === "") {
        return;
      }
      const container = document.createElement("div");
      container.setAttribute("data-playlist-block", "true");

      const ReactPlayerWrapper = () => {
        const theme = useTheme(); // Use the MUI theme
        const [currentUrl, setCurrentUrl] = useState();
        //node.attrs.playlist?.items[0]?.url || ""

        useEffect(() => {
          // Ensure the first video is loaded by default
          setCurrentUrl(node.attrs.playlist.items[0]?.url || "");
        }, [node.attrs.playlist.items]);

        return (
          <Box
            sx={{
              //  backgroundColor: theme.palette.primary.main,
              // padding: theme.spacing(2),
              borderRadius: theme.shape.borderRadius,
              width: "100vw",
              // color: theme.palette.primary.contrastText,
            }}
          >
            <div style={{ ...theme.typography.h5 }}>
              {node.attrs.playlist.playlist.name}
            </div>
            <div
              style={{
                width: "100vw",
                border: "1px solid black",
                // backgroundColor: theme.palette.grey[200],
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 10,
                  width: "100%",
                }}
              >
                {node.attrs.playlist.items.map((item, index) => (
                  <div key={index}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setCurrentUrl(item.url)}
                      style={{ ...theme.typography.button }}
                    >
                      {item.label}
                    </Button>
                  </div>
                ))}
              </div>
              <Box style={{ marginTop: 2, width: "90vw", maxWidth: 600 }}>
                <ReactPlayer
                  width="100%"
                  // height="100%"
                  url={currentUrl}
                  controls={true}
                />
              </Box>
            </div>
          </Box>
        );
      };

      // Render the React component into the container
      // ReactDOM.render(<ReactPlayerWrapper />, container);

      // Use ReactDOM to render the control layout
      const root = createRoot(container);
      root.render(<ReactPlayerWrapper />);

      return {
        dom: container,
        contentDOM: null, // No editable content inside this block
      };
    };
  },

  addCommands() {
    return {
      insertPlaylistBlock:
        (playlist) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "playlistBlock",
            attrs: { playlist },
          });
        },
      removePlayListBlock:
        () =>
        ({ state, dispatch }) => {
          const { selection } = state;
          const { tr } = state;

          state.doc.nodesBetween(selection.from, selection.to, (node, pos) => {
            if (node.type.name === "playlistBlock") {
              const transaction = tr.deleteRange(pos, pos + node.nodeSize);
              dispatch(transaction);
            }
          });

          return true;
        },
    };
  },
});

export default PlaylistBlock;
