import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  // Button,
  // TextField,
  // Checkbox,
  // FormControlLabel,
  // MenuItem,
  // Select,
  // InputLabel,
  // FormControl,
} from "@mui/material";
import NavForm from "./NavForm.js";

import { useTheme } from "@mui/material/styles";

import { appConfig } from "../../config.js";

const AddNavigationItem = ({ show, refresh, pages }) => {
  const theme = useTheme();

  const [newMenu, setNewMenu] = useState({
    label: "",
    parent_id: null,
    page_id: "pages...",
    position: 1,
    is_visible: false,
  });

  const handleInputChange = (e) => {
    // console.log(e);
    const { name, value } = e.target;
    setNewMenu((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setNewMenu((prev) => ({ ...prev, is_visible: e.target.checked }));
  };

  // Handle form submission to add a new menu/submenu
  const handleSubmit = async (e) => {
    // console.log("newMenu");
    e.preventDefault();
    try {
      const resp = await axios.post(
        `${appConfig.NODEURL}api/navigation`,
        newMenu,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
            "Content-Type": "application/json", // Add content-type if sending JSON
          },
        }
      );
      refresh(resp.data.id);
    } catch (error) {
      console.error("Error adding navigation item:", error);
    }
  };

  if (!show) {
    return null;
  }
  return (
    <NavForm
      handleCheckboxChange={handleCheckboxChange}
      handleInputChange={handleInputChange}
      handleSubmit={handleSubmit}
      menu={newMenu}
      text="Add Menu"
      pages={pages}
    />
  );
};

export default AddNavigationItem;
