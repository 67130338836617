import React from "react";
import HeroForm from "../components/CuteCms/HeroForm";
import Header from "../components/Header";

const Images = () => {
  return (
    <Header title="cms/images">
      <h1>Images</h1>
      <HeroForm />
    </Header>
  );
};

export default Images;
