// AddEvent.js
import React, { useState } from "react";
import CommonInputs from "./CommonInputs";
import { appConfig } from "../../config.js";
function AddEvent({ fields }) {
  const [event, setEvent] = useState({
    name: "",
    start_time: new Date().toLocaleDateString(),
    end_time: new Date().toLocaleDateString(),
    type: "monday",
    json: {},
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${appConfig.NODEURL}api/events`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(event),
    })
      .then((res) => res.json())
      .then((data) => console.log("Event added:", data))
      .catch((err) => console.error("Error adding event:", err));
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Add Event</h2>
      <CommonInputs fields={fields} event={event} setEvent={setEvent} />
      <button type="submit">Add Event</button>
    </form>
  );
}

export default AddEvent;
