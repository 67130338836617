import React from "react";
import { Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Zoom from "@mui/material/Zoom";
import DanceForm from "./index.js";
import { _fields } from "../../config";

const DancerRow = (props) => {
  const exclude = props.exclude;
  const dancer = props.dancer;
  const showNoteRow = props.showNoteRow;
  const setUpEdit = props.setUpEdit;
  const selectedRow = props.selectedRow;
  const showNote = props.showNote;
  const deleteDancer = props.deleteDancer;
  const setSelectedRow = props.setSelectedRow;
  const resetField = props.resetField;
  const getDancers = props.getDancers;

  const theme = useTheme();

  const sortEvent = (danceEvent, dancer, field) => {
    // console.log(dancer);
    // console.log(field);

    if (field[0] === "phone") {
      // console.log(dancer[field[0]]);
      // return field[1];
      return dancer[field[0]];
    }

    if (field[0] === "createdOn") {
      return new Date(danceEvent).toLocaleString();
    }

    if (field[0] === "price" || field[0] === "phone") {
      // console.log(field[1]);
      // return field[1];
      return dancer[field[0]];
    }

    let ret = [];
    try {
      const alaCart = JSON.parse(danceEvent);

      Object.entries(alaCart).map((item) => {
        // console.log(item[1]);

        Object.entries(item[1]).map((itm) => {
          // console.log(itm);
          if (itm[1]) {
            // console.log(itm[0]);
            ret.push(itm[0]);
          }
        });
        // console.log(ret.join(","));

        //return JSON.stringify(ret);
      });
    } catch (err) {
      // console.log(err);
      return danceEvent;
    }
    const r = ret.map((r, index) => {
      return <div key={index}>{r}</div>;
    });
    return r;
  };

  if (selectedRow === dancer.orderId) {
    return (
      <DanceForm
        exclude={exclude}
        dancer={dancer}
        resetField={resetField}
        setSelectedRow={setSelectedRow}
        deleteDancer={deleteDancer}
        getDancers={getDancers}
      />
    );
  }

  const DanceFields = ({ exclude }) => {
    return (
      <>
        {Object.entries(_fields)
          .filter((f) => exclude.indexOf(f[0]) === -1)
          .map((field, index) => {
            return (
              <div
                title={field[0]}
                key={index}
                style={{ textAlign: "left", width: 180, overflow: "hidden" }}
              >
                {typeof dancer[field[0]] === "boolean"
                  ? dancer[field[0]]
                    ? "yes"
                    : "no"
                  : sortEvent(dancer[field[0]], dancer, field)}
              </div>
            );
          })}
      </>
    );
  };

  // Main View
  return (
    <div
      id={dancer.orderId}
      onClick={() => setUpEdit(dancer)}
      style={{
        cursor: "pointer",
        backgroundColor:
          selectedRow === dancer.orderId
            ? theme.palette.grey[300]
            : showNote === dancer.orderId
            ? theme.palette.grey[200]
            : "#ffffff",
        color:
          selectedRow === dancer.orderId
            ? "#000" //theme.palette.info.contrastText
            : showNote === dancer.orderId
            ? "#000" //theme.palette.info.contrastText
            : "#000",
        // bacgroundColor: "green",
      }}
      key={"dancer"}
      onMouseOver={() => showNoteRow(dancer.orderId)}
    >
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <DanceFields exclude={exclude} />
        {/* <div style={{ width: 180, overflow: "hidden" }}>{dancer.firstname}</div>
        <div style={{ width: 200, overflow: "hidden" }}>{dancer.lastname}</div>
        <div style={{ width: 200, overflow: "hidden" }}>{dancer.email}</div>
        <div style={{ width: 100 }}>{dancer.phone}</div>
        <div style={{ width: 100 }}>{dancer.student ? "Yes" : "No"}</div>
        <div style={{ width: 100 }}>{dancer.housing ? "Yes" : "No"}</div>
        <div style={{ width: 100 }}>{dancer.dietary}</div>
        <div style={{ width: 100 }}>{dancer.waltzattend}</div>
        <div style={{ width: 100 }}>{dancer.price}</div> */}

        {/* <div style={{ width: 200, textAlign: "left" }}>{dancer.price}</div>
        <div style={{ width: 200, textAlign: "left" }}>
          {new Date(dancer.createdOn).toLocaleString()}
        </div>
        <div style={{ width: 200 }}>{dancer.orderId}</div> */}
        <div
          className="noprint"
          style={{
            border: "1px none yellow",
            display: "flex",
            flexWrap: "nowrap",
          }}
        ></div>
      </div>
      <div
        style={{
          width: "fit-content",
          border: `2px solid ${theme.palette.info.light}`,
          // backgroundColor: theme.palette.info.light,
          // color: theme.palette.info.contrastText,
          display: showNote === dancer.orderId ? "block" : "block",
          borderRadius: 3,
          padding: 2,
          paddingTop: 0,
          margin: 3,
          marginTop: 0,
        }}
      >
        {dancer.note ? dancer.note : "no notes"}
      </div>
      <hr />
    </div>
  );
};

export default DancerRow;
