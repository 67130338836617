import React, { useState, useEffect } from "react";
import axios from "axios";
import { appConfig } from "../../config";
const UserUpdatePassword = ({ userId, token, email }) => {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState("");
  const [approved, setApproved] = useState(false);
  const [banned, setBanned] = useState(false);
  const [role, setRole] = useState("");
  const NODEURL = appConfig.NODEURL;
  useEffect(() => {
    // Fetch the user details from the backend

    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${NODEURL}api/users/${userId}?email=${email}`
        );
        setUser(response.data);
        setApproved(response.data.approved);
        setBanned(response.data.banned);
        setRole(response.data.role);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data", error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleUpdate = async () => {
    try {
      await axios.put(`${NODEURL}api/users/${userId}?token=${token}`, {
        password,
        approved,
        banned,
        role,
      });
      console.log("User updated successfully");
    } catch (error) {
      console.error("Error updating user", error);
      console.log("Error updating user");
    }
  };

  if (loading) return <p>Loading...</p>;

  return (
    <div>
      <h2>Change Password {user.username}</h2>

      <div>
        <label>New Password:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      <button onClick={handleUpdate}>Update Password</button>
    </div>
  );
};

export default UserUpdatePassword;
