import React, { Component } from "react";
import Rte from "../components/Rte";

class RichTextEditor extends Component {
  render() {
    return (
      <div>
        <Rte />
      </div>
    );
  }
}

export default RichTextEditor;
