import React, { useState, useEffect, useRef } from "react";
import {
  // TextField,
  Button,
  // Box,
  // Checkbox,
  // FormControlLabel,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";

import {
  // createTheme,
  useTheme,
  // styled,
  // alpha,
  // ThemeProvider,
} from "@mui/material/styles";

import Header from "../components/Header/index.js";

import Login from "../components/Login/index.js";

const LoginRegister = () => {
  const theme = useTheme();
  return (
    <Header title="log in">
      <Login />
    </Header>
  );
};

export default LoginRegister;
