export const appConfig = {
  PORT: "3008",
  NODEURL: "/",
  PAYPAL_EMAIL: "sb-u4mbh32516264@business.example.com",
  PAYPAL_ENV: "https://www.paypal.com/cgi-bin/webscr",
  HOME: "https://register.contranooga.us",
  PAYPAL_CLIENT_ID:
    "AQ27N8MN2-g9NYzup2_rxK7MBi3OhuR3cEwLW8TS_jQ0-xxX-viRfGzelui9mCdOYiMLUjD-HzlTiNPk",
  THIRD_DOMAIN: "register.contranooga.us",
  PAYPAL_RESTBASEURL: "https://www.paypal.com",
 DANCERSCODE: "CTDS3233",
EARLYBIRDDATE: "2025-01-03",
EXCLUDEFIELDS: ["unit_amount", "quantity", "name"],
EVENT:"Weekend",
};

export const contact = {
  email: "dancers@contranooga.us",
  message: "4138137109",
};

const EARLYBIRD_OFFSET = -5; // Eastern Time is UTC-5
const now = new Date();
const nowInEST = new Date(
  now.getTime() + now.getTimezoneOffset() * 60000 + EARLYBIRD_OFFSET * 3600000
);

const isEarlyBird =  new Date(`${appConfig.EARLYBIRDDATE}T00:00:00-05:00`) > nowInEST;

export const prices = {
  Weekend: [130, 90, 145, 100],
  Friday: [35, 25, 35, 25],
  Saturday: [35, 25, 35, 25],
  SaturdayNight: [40, 30, 40, 30],
  Sunday: [35, 25, 35, 25],
};

export const prices2 = {
  Weekend: isEarlyBird ? [130, 90] : [145, 100],
  Friday: isEarlyBird ? [35, 25] : [35, 25],
  Saturday: isEarlyBird ? [35, 25] : [35, 25],
  SaturdayNight: isEarlyBird ? [40, 30] : [40, 30],
  Sunday: isEarlyBird ? [35, 25] : [35, 25],
};

const mprice = isEarlyBird
  ? Object.entries(prices)[0][1][0]
  : Object.entries(prices)[0][1][2];

export const _fields = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  student: false,
  housing: false,
  // dietary: "omnivore",
  price: mprice,
  unit_amount: mprice,
  quantity: 1,
  // waltzattend: "select...",
  name: "dance_admission",
  event: "Weekend",
  createdOn: "",
  orderId: "",
};

export const playlist = [
  {
    url: "https://youtu.be/cb4-nbYOU5k",
    label: "2020 Chattaboogie One",
  },
  {
    url: "https://youtu.be/a-LfWhcotRM",
    label: "2020 Chattaboogie Two",
  },
  {
    url: "https://youtu.be/rwjMxc5GIUQ",
    label: "2020 Chattaboogie Three",
  },
  {
    url: "https://youtu.be/gjV5zm0BYkY",
    label: "2020 Chattaboogie Four",
  },
  {
    url: "https://youtu.be/i0NVg80JLT8",
    label: "Angel Band",
  },
  {
    url: "https://youtu.be/r4nWJGow-Qw",
    label: "Waltz",
  },
];
