// EditEvent.js
import React, { useEffect, useState } from "react";
import CommonInputs from "./CommonInputs";
import { appConfig } from "../../config.js";
function EditEvent({ fields, eventId, onClear }) {
  const [event, setEvent] = useState(null);

  useEffect(() => {
    fetch(`${appConfig.NODEURL}api/events/${eventId}`)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setEvent(data);
      })
      .catch((err) => console.error("Error fetching event:", err));
  }, [eventId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`${appConfig.NODEURL}api/events/${eventId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(event),
    })
      .then((res) => res.json())
      .then((data) => console.log("Event updated:", data))
      .catch((err) => console.error("Error updating event:", err));
  };

  if (!event) return <p>Loading...</p>;

  return (
    <form onSubmit={handleSubmit}>
      <h2>Edit Event</h2>
      <CommonInputs fields={fields} event={event} setEvent={setEvent} />
      <button type="submit">Save Changes</button>
      <button type="button" onClick={onClear}>
        Cancel
      </button>
    </form>
  );
}

export default EditEvent;
