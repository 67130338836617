import React, { useState, useEffect, useRef } from "react";
// import {
//   TextField,
// Button,
// Box,
// Checkbox,
// FormControlLabel,
// InputLabel,
// Select,
// MenuItem,
// } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Jwt from "../../components/Login/Jwt.js";
import "./dancers.css";
import { appConfig } from "../../config.js";

const NODEURL = appConfig.NODEURL;

export default function Policies(props) {
  const theme = useTheme();

  // const [token, setToken] = useState("");

  // const [loggedIn, setLoggedIn] = useState(false);

  // useEffect(() => {
  //   setToken(localStorage.getItem("token"));
  // }, []);

  // useEffect(() => {
  //   setLoggedIn(token ? true : false);
  //   console.log(loggedIn);
  // }, [token]);

  // const Jwt = () => {
  //   const [show, setShow] = useState(false);

  //   const logout = () => {
  //     localStorage.removeItem("token");
  //     setLoggedIn(false);
  //     // setShow(true);
  //   };

  //   const login = () => {
  //     setShow(true);
  //     // setLoggedIn(true);
  //   };

  //   return (
  //     <div>
  //       {show ? (
  //         <div style={{ width: 20 }}>
  //           <Login setLoggedIn={setLoggedIn} setShow={setShow} login />
  //         </div>
  //       ) : loggedIn ? (
  //         <Button
  //           style={{
  //             ...theme.typography.littleLink,
  //             backgroundColor: theme.palette.warning.main,
  //             // position: "absolute",
  //             // right: 0,
  //             // top: 0,
  //           }}
  //           onClick={logout}
  //         >
  //           Logout
  //         </Button>
  //       ) : (
  //         <a
  //           style={{
  //             ...theme.typography.littleLink,
  //             // position: "absolute",
  //             // right: 0,
  //             // top: 0,
  //           }}
  //           onClick={login}
  //         >
  //           Login
  //         </a>
  //       )}
  //     </div>
  //   );
  // };

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <Jwt />
        <Link
          style={{
            ...theme.typography.littleLink,
          }}
          to="/privacy-policy"
        >
          Privacy Policy
        </Link>
        <Link
          style={{
            ...theme.typography.littleLink,
          }}
          to="/user-agreement"
        >
          User Agreement
        </Link>
      </div>
    </div>
  );
}
