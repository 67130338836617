import React, { useEffect, useState, dangerouslySetInnerHTML } from "react";
import axios from "axios";
import {
  Box,
  // Select,
  // MenuItem,
  Button,
  // IconButton,
  // TextField,
  // Grid,
  // InputBase,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { appConfig } from "../../config";
import ConfirmButtons from "../widgets/ConfirmButtons.js";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import pretty from "pretty";

const HeroForm = () => {
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [image, setImage] = useState(null);

  const [heros, setHeros] = useState(null);

  const fetchHeros = async () => {
    try {
      const response = await axios.get(`${appConfig.NODEURL}api/hero`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      });

      setHeros(response.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  useEffect(() => {
    fetchHeros();
    return () => {};
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("image", image);

    try {
      await axios.post(`${appConfig.NODEURL}api/hero`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`, // Authorization header
          "Content-Type": "multipart/form-data",
        },
      });
      //alert("Hero content saved successfully!");
      fetchHeros();
    } catch (error) {
      console.error("Error saving hero content:", error);
    }
  };

  const handleDeleteHero = () => {};

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            border: "1px solid black",
            width: "fit-content",
            borderRadius: 5,
            padding: 5,
            alignItems: "center",
          }}
        >
          <div>
            <label>Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Subtitle</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              required
            />
          </div>

          <div>
            <label>Background Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>

          <Button sx={{ ...theme.typography.button }} type="submit">
            Add New Hero
          </Button>
        </div>
      </form>

      <textarea style={{ display: "none", whitespace: "wrap" }}>
        {heros &&
          JSON.stringify(
            heros.map((hero) => pretty(hero.imgmarkup)),
            null,
            3
          )}
      </textarea>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: "3vw",
          alignItems: "flex-start",
          justifyContent: "space-around",
          marginTop: 2,
          marginLeft: 2,
          border: 1,
          //   margin: "2vw",
        }}
      >
        {heros &&
          heros.map((hero, index) => {
            if (index > 1) {
              // return;
            }
            console.log(hero);
            return (
              <div key={hero.id} style={{ maxWidth: 120, maxHeight: 300 }}>
                <div style={{ ...theme.typography.h6 }}>{hero.title}</div>
                <ConfirmButtons
                  label="delete"
                  action={handleDeleteHero}
                  args={hero.id}
                  icon={<DeleteForeverIcon />}
                />
                <Button sx={{ ...theme.typography.button }}>Add</Button>
                <div
                  style={{ width: 100 }}
                  key={hero.id} // Assuming each hero object has a unique id
                  dangerouslySetInnerHTML={{ __html: hero.imgmarkup }} // Pass as an object with __html key
                />
                <div>{hero.subtitle}</div>
              </div>
            );
          })}
      </Box>
    </div>
  );
};

export default HeroForm;
