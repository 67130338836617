// CommonInputs.js
import React from "react";

const fields = [
  {
    name: "id",
    tableID: 97883,
    columnID: 1,
    dataTypeID: 23,
    dataTypeSize: 4,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "name",
    tableID: 97883,
    columnID: 2,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "start_time",
    tableID: 97883,
    columnID: 3,
    dataTypeID: 1114,
    dataTypeSize: 8,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "end_time",
    tableID: 97883,
    columnID: 4,
    dataTypeID: 1114,
    dataTypeSize: 8,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "body",
    tableID: 97883,
    columnID: 5,
    dataTypeID: 25,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "note",
    tableID: 97883,
    columnID: 6,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "email",
    tableID: 97883,
    columnID: 7,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "lat",
    tableID: 97883,
    columnID: 8,
    dataTypeID: 1700,
    dataTypeSize: -1,
    dataTypeModifier: 655370,
    format: "text",
  },
  {
    name: "lng",
    tableID: 97883,
    columnID: 9,
    dataTypeID: 1700,
    dataTypeSize: -1,
    dataTypeModifier: 655370,
    format: "text",
  },
  {
    name: "street",
    tableID: 97883,
    columnID: 10,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "city",
    tableID: 97883,
    columnID: 11,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "state",
    tableID: 97883,
    columnID: 12,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 54,
    format: "text",
  },
  {
    name: "zip",
    tableID: 97883,
    columnID: 13,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 24,
    format: "text",
  },
  {
    name: "geocode",
    tableID: 97883,
    columnID: 14,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "image_url",
    tableID: 97883,
    columnID: 15,
    dataTypeID: 25,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "web_url",
    tableID: 97883,
    columnID: 16,
    dataTypeID: 25,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "link_label",
    tableID: 97883,
    columnID: 17,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "link_description",
    tableID: 97883,
    columnID: 18,
    dataTypeID: 25,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "approved",
    tableID: 97883,
    columnID: 19,
    dataTypeID: 16,
    dataTypeSize: 1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "image",
    tableID: 97883,
    columnID: 20,
    dataTypeID: 17,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
  {
    name: "slug",
    tableID: 97883,
    columnID: 21,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "type",
    tableID: 97883,
    columnID: 22,
    dataTypeID: 1043,
    dataTypeSize: -1,
    dataTypeModifier: 259,
    format: "text",
  },
  {
    name: "json",
    tableID: 97883,
    columnID: 23,
    dataTypeID: 3802,
    dataTypeSize: -1,
    dataTypeModifier: -1,
    format: "text",
  },
];

function CommonInputs({ event, setEvent }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEvent((prev) => ({ ...prev, [name]: value }));
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return offsetDate.toISOString().slice(0, 16); // "YYYY-MM-DDTHH:MM"
  };

  return (
    <>
      <pre style={{ display: "none" }}>{JSON.stringify(event, null, 3)}</pre>
      <label>
        Name:
        <input
          type="text"
          name="name"
          value={event.name || ""}
          onChange={handleChange}
        />
      </label>
      <label>
        Start Time:
        <input
          type="datetime-local"
          name="start_time"
          value={formatDateForInput(event.start_time)}
          onChange={handleChange}
        />
      </label>
      <label>
        End Time:
        <input
          type="datetime-local"
          name="end_time"
          value={formatDateForInput(event.end_time)}
          onChange={handleChange}
        />
      </label>
      <label>
        Type:
        <input
          type="text"
          name="type"
          value={event.type || "monday"}
          onChange={handleChange}
        />
      </label>
      {/* <label>
        JSON:
        <textarea
          name="json"
          value={JSON.stringify(event.json || {})}
          onChange={(e) =>
            setEvent((prev) => ({
              ...prev,
              json: JSON.parse(e.target.value || "{}"),
            }))
          }
        />
      </label> */}
    </>
  );
}

export default CommonInputs;
