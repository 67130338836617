import React, { useState, useEffect, useRef } from "react";
import {
  // TextField,
  // Button,
  // Box,
  Checkbox,
  FormControlLabel,
  // FormControl,
  RadioGroup,
  Radio,
  // InputLabel,
  // Select,
  // MenuItem,
} from "@mui/material";
import { appConfig, prices2 } from "../../config";
import {
  // createTheme,
  useTheme,
  // styled,
  // alpha,
  // ThemeProvider,
} from "@mui/material/styles";

const EventOptions2 = ({
  // handleChange,
  handleToggle,
  handleEventAlacarteSwitch,
  selectedChoices,
  field,
}) => {
  const theme = useTheme();
  const choices = Object.entries(prices2);

  const [show, setShow] = useState(false);

  const [event, setEvent] = useState(field.event);

  // const handleEvent = (event) => {
  //   console.log(event);
  //   handleChange(event);
  //   setShow(event.target.value === "alacarte" ? true : false);
  // };

  useEffect(() => {
    console.log(selectedChoices);
    setShow(appConfig.EVENT !== field.event);
  }, [field.event]);

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
      }}
    >
      <RadioGroup
        id="event"
        style={
          {
            // minWidth: 300,
            // maxWidth: 400,
            // margin: "auto",
            // marginBottom: 20,
            // marginTop: 20,
            // backgroundColor: theme.palette.secondary.main,
            // color: "#ffffff",
          }
        }
        name="event"
        // value={survey.likert}
        onChange={(event) => handleEventAlacarteSwitch(event, choices)}
        // onChange={(event) => handleToggle(event)}
        row
      >
        <FormControlLabel
          value={appConfig.EVENT}
          control={<Radio />}
          label={appConfig.EVENT}
          checked={appConfig.EVENT === field.event}
        />
        <FormControlLabel
          value="alacarte"
          control={<Radio />}
          label="a la carte"
          checked={appConfig.EVENT !== field.event}
        />
      </RadioGroup>

      <div
        style={{
          marginTop: 10,
          marginLeft: 5,
          padding: 5,
          border: "1px solid black",
          borderRadius: 5,
          display: show ? "flex" : "none",
        }}
      >
        {choices.slice(1).map((choice, index) => (
          <FormControlLabel
            style={{ height: 20 }}
            key={"checkbox" + index}
            control={
              <Checkbox
                style={{ color: theme.palette.secondary.main }}
                checked={!!selectedChoices[choice[0]]}
                onChange={(event) => handleToggle(event, choice[0])}
                value={choice[1]}
              />
            }
            label={choice[0]} // Displaying the event name (choice[0])
          />
        ))}
      </div>
    </div>
  );
};

export default EventOptions2;
