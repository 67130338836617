import { jwtDecode } from "jwt-decode";

function isTokenExpired(token) {
  if (!token) return true; // If there's no token, consider it expired

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime; // True if expired
  } catch (error) {
    // Handle any error, including invalid token format
    console.error("Invalid token:", error);
    return true; // Consider invalid tokens as expired
  }
}

function isUserValidated() {
  const token = localStorage.getItem("token"); // Get the token from localStorage
  if (!token) return false; // If no token is found, user is not validated
  return !isTokenExpired(token); // Return true if the token is not expired
}

export default isUserValidated;
