import { Node } from "@tiptap/core";
import { createRoot } from "react-dom/client"; // Import the new API

import React, { useState } from "react";
import { useTheme, Box, Typography, Button } from "@mui/material";

const FlexBlock = Node.create({
  name: "flexBlock",
  group: "block",
  content: "block*",

  addAttributes() {
    return {
      flex: {
        default: {
          title: "Title Here",
          count: 2,
        },
        parseHTML: (element) =>
          JSON.parse(element.getAttribute("data-flex")) || {},
        renderHTML: (attributes) => ({
          "data-flex": JSON.stringify(attributes.flex),
        }),
      },
    };
  },

  parseHTML() {
    return [{ tag: "div[data-flex-block]" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { "data-flex-block": true, ...HTMLAttributes }, 0];
  },

  addCommands() {
    return {
      insertFlexBlock:
        (flex) =>
        ({ commands }) => {
          return commands.insertContent({
            type: "flexBlock",
            attrs: { flex },
          });
        },
    };
  },

  addNodeView() {
    return ({ node }) => {
      const container = document.createElement("div");
      container.setAttribute("data-flex-block", "true");

      // Create a contentDOM for Tiptap to manage editable content
      const contentDOM = document.createElement("div");
      contentDOM.contentEditable = "true";
      contentDOM.classList.add("flex-content"); // Add styling or structure here

      // Render React layout control components (without editable content)
      const LayoutControl = () => {
        const theme = useTheme();
        return (
          <Box
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              padding: theme.spacing(2),
              border: `1px solid ${theme.palette.primary.main}`,
              borderRadius: theme.shape.borderRadius,
            }}
          >
            <Typography variant="h6">Manage Layout</Typography>
            <Button>Add Box</Button>
            <Button>Remove Box</Button>
          </Box>
        );
      };

      // Use ReactDOM to render the control layout
      const root = createRoot(container);
      root.render(<LayoutControl />);

      // Append the contentDOM for editable content
      container.appendChild(contentDOM);

      return {
        dom: container,
        contentDOM, // Tiptap handles content here, React is hands-off
      };
    };
  },
});

export default FlexBlock;
