import React, { useState, useEffect } from "react";
import {
  //   TextField,
  Button,
  //   Box,
  //   Checkbox,
  //   FormControlLabel,
  //   InputLabel,
  //   Select,
  //   MenuItem,
} from "@mui/material";
import { CSVLink } from "react-csv";
import {
  //createTheme,
  useTheme,
  //   styled,
  //   alpha,
  //   ThemeProvider,
} from "@mui/material/styles";

import { appConfig } from "../config";

import { useSearchParams } from "react-router-dom";

import UserUpdatePassword from "../components/Login/UserUpdatePassword";

// import Header from "../components/Header/index.js";

import HeaderThin from "../components/Header/";

//import CloseIcon from "@mui/icons-material/Close";

//import Header from "../components/Header/index.js";

const NODEURL = appConfig.NODEURL;

const UserManage = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const token = searchParams.get("token");

  // useEffect(() => {
  //   // You can perform any data-fetching or re-render logic here
  //   console.log("Page refreshed due to login state change");
  // }, [trig]);
  return (
    <HeaderThin title="Users">
      <div>
        <UserUpdatePassword userId={id} token={token} />
      </div>
    </HeaderThin>
  );
};

export default UserManage;
