import React from "react";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";
import { useTheme, useMediaQuery } from "@mui/material";

const Logo = () => {
  const [searchParams] = useSearchParams();
  const squashHeader = searchParams.has("squashheader");
  const theme = useTheme();
  if (squashHeader) {
    return (
      <a
        href="https://contranooga.us/"
        style={{
          // color: "#ffffff",

          fontSize: "1em",
          position: "absolute",
          margin: 0,
          padding: 0,
          left: 0,
          top: 0,

          textDecoration: "none",
        }}
      >
        Chattanooga Contra Dancers
      </a>
    );
  }
  return (
    <a
      href="https://contranooga.us/"
      style={{
        // color: "#ffffff",

        fontSize: "1em",
        position: "absolute",
        margin: 0,
        padding: 0,
        left: 0,
        top: 0,

        textDecoration: "none",
      }}
    >
      <Box
        // className="box"
        sx={{
          ...theme.typography.h6,
          // backgroundImage: "url('/banner2.png')",
          // opacity: 0.2,
          // backgroundColor: "rgba(255,0,0,0.6)",
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
          // backgroundColor: theme.palette.primary.contrastText,
          color: theme.palette.info.dark,
          margin: 0.1,
          padding: {
            xs: 0,
            sm: 0.5,
          },

          borderRadius: 1,
          margin: 1,
          marginTop: {
            xs: 3,
            // marginTop: 34, // theme.spacing(1) for extra small screens
            // sm: "none", // theme.spacing(2) for small screens
            // md: "none", // theme.spacing(3) for medium screens
            lg: 1,
            // marginTop: 4,
            // marginLeft: 14, // theme.spacing(4) for large screens
            // xl: "none", // theme.spacing(5) for extra-large screens
          },
          width: {
            xs: 500,
            sm: 500,
            md: 500,
            lg: 200,
            xl: 200,
            // xl: 300,
            // marginTop: 4,
            // marginLeft: 14, // theme.spacing(4) for large screens
            // xl: "none", // theme.spacing(5) for extra-large screens
          },
        }}
      >
        <div
          style={{
            width: "100%",
            position: "relative",
            margin: 0,
            padding: 0,
          }}
        >
          <Box
            sx={(theme) => ({
              width: 300,
              fontWeight: "bold",
              position: "absolute",

              letterSpacing: 4,
              fontSize: "1.8em",
              lineHeight: "1.5em",
              left: 0,
              top: 0,
              opacity: 1,
              color: theme.palette.primary.contrastText,
              // width: 350,
              // backgroundColor: "yellow",
              [theme.breakpoints.down("lg")]: {
                width: "100vw",
                letterSpacing: 2,
                fontSize: "1.2em",
                lineHeight: ".9em",
                // width: 250,
                // backgroundColor: "green",
                // ...theme.typography.h2,
              },
            })}
          >
            Chattanooga Traditional Dance Society
          </Box>
        </div>
      </Box>
    </a>
  );
};

export default Logo;
