import { Node } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";

const LabelStyle = Node.create({
  name: "labelStyle",
  group: "block",
  content: "block*",
  draggable: false,

  parseHTML() {
    return [{ tag: "div[data-label-style]" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", { ...HTMLAttributes, "data-label-style": true }, 0];
  },

  addNodeView() {
    return ({ node, HTMLAttributes }) => {
      return (
        <NodeViewWrapper as="div" style={HTMLAttributes.style}>
          {node.content}
        </NodeViewWrapper>
      );
    };
  },

  addCommands() {
    return {
      applyLabelStyle:
        (style) =>
        ({ commands }) => {
          return commands.setNode("labelStyle", { style });
        },
    };
  },
});

export default LabelStyle;
