import React, { useState, useEffect, useRef } from "react";
// import { useTheme } from "@mui/material/styles";
import ValidateJwt from "../components/Login/ValidateJwt.js";
import { useNavigate } from "react-router-dom";

import isUserValidated from "../util/isUserValidated.js";
import Header from "../components/Header";
import { appConfig } from "../config.js";
import CuteCms from "../components/CuteCms/index.js";

const NODEURL = appConfig.NODEURL;

const ManageCms = ({ trig }) => {
  //import { useNavigate } from "react-router-dom";
  const navigate = useNavigate();
  useEffect(() => {
    if (!isUserValidated()) {
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
          // cartItems: items ? items : [_fields],
        },
      });
    }
  }, [trig]);

  // const theme = useTheme();
  // const [id, setId] = useState(0);

  return (
    <Header title="CMS">
      <ValidateJwt />
      <CuteCms />
      {/* {id > 0 && <EditPage pageId={id} />} */}
    </Header>
  );
};

export default ManageCms;
