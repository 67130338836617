import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

import { useNavigate } from "react-router-dom";
// import Login from "./Login";

import Jwt from "./Jwt";

import isUserValidated from "../../util/isUserValidated";

const ValidateJwt = () => {
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  //   const [show, setShow] = useState(false);

  function isTokenExpired(token) {
    if (!token) {
      return false;
    }
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert milliseconds to seconds
    return decoded.exp > currentTime;
  }

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  useEffect(() => {
    // console.log(isTokenExpired(token));
    if (!isUserValidated()) {
      localStorage.removeItem("token");
      navigate("/login", {
        state: {
          returnTo: window.location.pathname + "/" + window.location.search,
          // cartItems: items ? items : [_fields],
        },
      });
    }
    // setShow(!isTokenExpired(token));
    // if false do some redirect here
  }, [token]);

  return (
    <div>
      {/* Token is {token ? token : "empty"} and it is{" "}
      {isTokenExpired(token).toString()} */}
      {/* <Jwt /> */}
    </div>
  );
};

export default ValidateJwt;
