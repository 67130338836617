import React, { useState, useEffect } from "react";
// import {
//   TextField,
// Button,
// Box,
// Checkbox,
// FormControlLabel,
// InputLabel,
// Select,
// MenuItem,
// } from "@mui/material";

// import {
// createTheme,
// useTheme,
// styled,
// alpha,
// ThemeProvider,
// } from "@mui/material/styles";

import Header from "../components/Header/index.js";

import axios from "axios";

import { useSearchParams } from "react-router-dom";

import { appConfig } from "../config";

const VerifyEmail = () => {
  //   console.log("VerifyEmail");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const [data, setData] = useState("");
  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`, // Add the token to the Authorization header
    };

    axios
      .post(
        `${appConfig.NODEURL}api/auth/verifyemail`,
        { token: token, userId: userId },
        { headers }
      )
      // .then((response) => response.json())
      .then((data) => {
        //   const token = data.token;
        //   console.log(data);
        setData(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [token, userId]);

  return (
    <Header title="Verify Email">
      <div>
        {data?.data?.rows[0]?.email_verified ? (
          <h2>Email Verified. Thank you</h2>
        ) : (
          <div></div>
        )}
        <pre style={{ display: "none" }}>{JSON.stringify(data, null, 3)}</pre>
      </div>
    </Header>
  );
};

export default VerifyEmail;
