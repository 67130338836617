import { Node } from "@tiptap/core";

const CustomDiv = Node.create({
  name: "customDiv",

  group: "block",

  content: "block*",

  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", HTMLAttributes, 0];
  },
});

export default CustomDiv;
